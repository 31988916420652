<template>
  <v-snackbar
    :value="value"
    vertical
    timeout="-1"
    color="success"
  >
    {{text}}
    <v-btn
      class="ml-2"
      @click="handleExit"
    >
      Войти в личный кабинет
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AuthSnackBar',
  props: {
    value: Boolean,
    text: String
  },
  methods: {
    handleExit () {
      return this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style scoped>

</style>
