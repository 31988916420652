<template>
  <div class="section-main">
    <em></em>
    <v-card
      class="mx-auto"
      max-width="500"
    >
      <v-form
        class="loginForm pa-10 text-center"
      >
        <div class="pt-4 text-center">
          <h4>Зарегистрироваться</h4>
        </div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pb-1 pt-1"
            >
              <v-text-field
                v-model="detail.username"
                :error-messages="checkErrors('detail.username')"
                label="Имя"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-1 pb-1"
            >
              <v-text-field
                v-model="detail.email"
                :error-messages="checkErrors('detail.email')"
                label="Адрес электронной почты"
                hint="Логин и пароль от личного кабинета вышлем на указанную вами электроную почту"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-1 pb-1"
            >
              <PhoneList v-model="detail.userPhones"/>
            </v-col>
          </v-row>
          <v-row class="PrivacyPolicy">
            <v-col
              cols="12"
              md="4"
              class="pr-0 pt-1 pb-1"
            >
              <v-checkbox
                class="mr-2"
                v-model="privacyPolicyCheckBox"
                :error-messages="$v.privacyPolicyCheckBox.$anyError ? 'Примите, чтобы продолжить' : ''"
                label="Принимаю "
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="mt-5 pl-0 pr-0 pb-1 pt-1"
            >
              <router-link
                active-class="active"
                :to="{name:'privacyPolicy'}"
              >
                <v-list-item-title>
                  <a>политику конфиденциальности</a>
                </v-list-item-title>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            color="blue"
            class="mr-4 white--text"
            @click="submit"
            :loading="isLoading"
          >
            Зарегестрироваться
          </v-btn>
        </v-card-actions>
        <AuthSnackBar
          :value="snackbar"
          text="Спасибо за регистрацию! На указанном email адресе вы найдете логин и пароль для входа."
        />
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import { mask } from '@titou10/v-mask'

import phoneComputed from '@/components/mixins/phoneComputed'
import PhoneList from '@/components/views/Account/Profile/PhoneList'
import UserPhone from '@/components/structures/userPhone'
import loading from '@/components/mixins/loading'
import AuthSnackBar from '@/components/layouts/Auth/AuthSnackBar'

export default {
  mixins: [validationErrors, phoneComputed, loading],
  name: 'Registration',
  components: { AuthSnackBar, PhoneList },
  directives: {
    mask
  },
  data: () => ({
    privacyPolicyCheckBox: true,
    snackbar: false,
    detail: {
      email: '',
      username: '',
      userPhones: [new UserPhone()]
    }
  }),
  provide () {
    return {
      $v: this.$v
    }
  },
  validations () {
    return {
      detail: {
        email: {
          required,
          email,
          isUnique (value) {
            let result = true
            if (value && this.$v.detail.email.$dirty) {
              result = this.$store.dispatch('auth/isUnique', {
                data: {
                  email: value
                }
              })
            }
            return result
          }
        },
        username: {
          required
        },
        userPhones: {
          required,
          $each: {
            phone: {
              phone: {
                required,
                minLength: minLength(11)
              }
            }
          }
        }
      },
      privacyPolicyCheckBox: {
        required
      }
    }
  },
  methods: {
    async submit () {
      if (!this.isLoading) {
        this.isLoading = true
        this.$v.$touch()
        await this.checkPending()
        if (!this.$v.$anyError) {
          let response = await this.$store.dispatch('auth/signUp', { data: this.detail })
          if (response) {
            this.snackbar = true
          }
        }
        this.isLoading = false
      }
      return true
    }
  }
}
</script>

<style>
  @media (max-width: 800px) and (min-width: 200px) {
    .PrivacyPolicy {
      padding: 0;
      margin: 0;
    }
  }

</style>

<style scoped>
</style>
